import React from 'react';
import { NavLink } from 'react-router-dom';

import TypeDesign from './../TypeDesign';


var bgimg1 = require('./../../../images/banner/web-development-banner.jpg');
var bgimg2 = require('./../../../images/background/cross-line2.png');


class ClientsLogo1 extends React.Component {
    render() {

        return (
          <>
            <div
              className="section-full  mobile-page-padding dfbb7680978 p-t80 p-b10 bg-repeat ertyuioiu1"
              style={{ backgroundImage: "url(" + bgimg1 + ")" }}
            >
              <div className="container ">
                {/* TITLE START */}
                <div className="row d-inline-flex">
                  <div className="col-md-6">
                    <div className="section-head mnbv123">
                      <div className="sx-separator-outer ">
                        <div className="bg-repeat-x color-change ewgr8900">
                          <h3 className="ert678">
                           Unlocking the Power of AI Transforming Your World
                          </h3>
                          <p>Explore the cutting-edge advancements in Artificial Intelligence and discover how AI is revolutionizing industries and shaping the future.</p>
                        </div>
                      </div>
                       <TypeDesign/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mnbv1231">
                      <img src={require("./../../../images/AI/artificial-intelligence1.png")} alt="" width="60%"/>
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
              </div>
            </div>
          </>
        );
    }
};

export default ClientsLogo1;
import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import AiAbout from "./../Elements/ArtificialIntelligence/AiAbout";
import AiBanner from "./../Elements/ArtificialIntelligence/AiBanner";
import AiType from "./../Elements/ArtificialIntelligence/AiType";
import Aitech from "./../Elements/ArtificialIntelligence/AiTech";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
       <Helmet>
          <title>AI is revolutionizing the way we create, manage, and interact with web</title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/search-engine-optimization" />
     </Helmet>
     <Header2 />
        <div className="page-content">
         <AiBanner />
         <AiAbout />
         <AiType />
         <Aitech/>
       </div>

    <Footer2 />

      </>
    );
  }
}

export default Service1;

import React from 'react';
import { NavLink } from 'react-router-dom';

const logos = [
  { image: require('./../../../images/AI/market-intelligence.png'),
      heading: "Collect" 
  },
  { 
      image: require('./../../../images/AI/annotation.png'),
      heading: " Annotate" 
  },
      {
       image: require('./../../../images/AI/product-development.png'),
      heading: " Develop" 
  },
      {
       image: require('./../../../images/AI/deployment.png'),
      heading: " Deploy" 
  },
      {
       image: require('./../../../images/AI/deployment.png'),
      heading: " Connect" 
  },
      {
        image: require('./../../../images/AI/rpa.png'),
      heading: " Maintain" 
  }

    
]

var bgimg1 = require('./../../../images/background/bg-12.jpg');
var bgimg2 = require('./../../../images/background/cross-line2.png');

class ClientsLogo1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding p-t80 p-b10 bg-repeat ">
                 <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
               <div className="blur-bg blur-bg-a"></div>
               <div className="blur-bg blur-bg-b"></div>
               <div className="blur-bg blur-bg-c"></div>
            </aside>
         </div>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="">
                                    <h3 className="ert678">Solve real-world problems with AI</h3>
                                    <h6>Suite lets you create and scale real-time computer vision and deep learning applications.</h6>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b40">
                                <div className="row justify-content-center mnbvcxcvbnbvc">
                                    {logos.map((item, index) => (
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-6 m-b30" key={index}>
                                            <div className="AI-pic11">
                                                <NavLink to={"#"} className="">
                                                <img className="AI-cons" src={item.image} alt=""/>
                                                <h5>{item.heading}</h5>
                                            </NavLink>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Clients</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default ClientsLogo1;
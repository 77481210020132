import React from "react";
import Header2 from "./../Common/Header3";
import Banner from "./../Elements/AboutUs/AboutBanner";
import AboutMission from "./../Elements/AboutUs/AboutMission";
import AboutBusinesses from "./../Elements/AboutUs/AboutBusinesses";
import AboutAboutUs from "./../Elements/AboutUs/AboutAboutUs";
import AboutAboutInNumber from "./../Elements/AboutUs/AboutAboutInNumber";
import AboutWhatWeDo from "./../Elements/AboutUs/AboutWhatWeDo";
import AboutFAQ from "./../Elements/AboutUs/AboutFAQ";
import Aboutcul from "./../Elements/AboutUs/Aboutcul";
import Testimonials from "./../Elements/Testimonials";
import Footer2 from "./../Common/Footer2";
import Footer from "./../Common/Footer";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/main-slider/slider5/web-development-banner.jpg");

class About1 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Shadow infosystem | Your Trusted Digital Solutions Provider</title>
          <meta
            name="description"
            content="Learn about Shadow infosystem, a trusted digital solutions provider delivering innovative, result-driven services for businesses worldwide. Discover our expertise and mission today."
          />
          <meta name="keywords" content="Digital marketing company" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/about-us" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <Banner />
          <AboutAboutUs />
          <AboutAboutInNumber />
          <AboutWhatWeDo />
          <AboutMission />
          <AboutBusinesses />
          <AboutFAQ />
          <Aboutcul />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default About1;

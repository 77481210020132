import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import SMOBanner from "./../Elements/SMO/SMOBanner";
import SMOAbout from "./../Elements/SMO/SMOAbout";
import SMOClient from "./../Elements/SMO/SMOClient";
import WebWebSiteProcess from "./../Elements/SMO/WebWebSiteProcess";
import SMOWhyChooseUs from "./../Elements/SMO/SMOWhyChooseUs";
import SMOServices from "./../Elements/SMO/SMOServices";
import SMMOnline from "./../Elements/SMO/SMMOnline";
import SMOFAQ from "./../Elements/SMO/SMOFAQ";
import WebFAQ from "./../Elements/SMO/WebFAQ";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Social Media Marketing Services | Social Media Advertising</title>
          <meta
            name="description"
            content="Boost your brand's presence with expert social media marketing, growth strategies, and targeted advertising services by Shadow infosystem. Drive engagement and reach your audience effectively."
          />
          <meta name="keywords" content="SEO Services Provider in Delhi" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/social-media-marketing"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <SMOBanner />
          <SMOAbout />
          <SMOServices />
          <WebWebSiteProcess />
          {/*<CommenEnquiry />*/}
          <SMMOnline />
          <SMOWhyChooseUs />
          <WebFAQ />
          <SMOClient />
          <Testimonials />
          <SMOFAQ />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;

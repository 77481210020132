import React from 'react';
import Header2 from './../Common/Header3';
import Footer2 from './../Common/Footer2';
import CSRbanner from './../Elements/CSRatShadow/CSRbanner';
import CSRAboutUs from './../Elements/CSRatShadow/CSRAboutUs';
import CSRWhatWeDo from './../Elements/CSRatShadow/CSRWhatWeDo';
import CSRFAQ from './../Elements/CSRatShadow/CSRFAQ';
import { Helmet } from 'react-helmet';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
            <Helmet>
          <title>
             CSR at Shadow infosystem
          </title>
          <meta name="description" content="Our CSR initiatives reflect our commitment to making a positive impact through sustainability, community development, & ethical practices" />
          <meta name="keywords" content="CSR at Shadow infosystem" />
          <link rel="canonical" href=" https://www.shadowinfosystem.com/csr-at-shadow" />
        </Helmet>
                <Header2 />
                <div className="page-content">
                   <CSRbanner/> 
                   <CSRAboutUs/>
                   {/*<CSRWhatWeDo/>
                   <CSRFAQ/>*/}
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;
import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                   
                    <div className="col-md-10 offset-md-1">
                      <div className="sx-separator-outer separator-center">
                        <div className="ertyui">
                          <h1 className="ert678 heading">
                           Artificial intelligence (AI)
                          </h1>

                          <p>Artificial Intelligence (AI) is no longer a futuristic concept; it's a transformative force reshaping our world. From the smartphones in our pockets to the self-driving cars on our roads, AI is seamlessly integrated into our daily lives.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </>
    );
  }
}

export default About3;

import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactPlayer from "react-player";
import TypeDesign from "./../TypeDesign";

var bgimg1 = require("./../../../images/background/home-banner.jpg");

class Blog2 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      items: 1,
      dots: false,
      animateOut: "fadeOut",
    };
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b50 ertyu45 bacg-ser"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer text-center">
                <div className="color-change bg-moving bg-repeat-x white-text">
                  <h3 className="wfgrbwe ert678 mb-3">
                    One platform<br/> Every use case <br/>Superior performance
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images/AI/technology.png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1 ">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/AI/technology.png")}
                          alt=""
                        />
                        <h4 className="post-title">The Fundamentals of AI</h4>
                      </div>
                      <div className="">
                        <p> Understand the core concepts of machine learning, deep learning, and natural language processing.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images//AI/artificial-intelligence (1).png")}
                      alt=""
                    />
                    <div className="sx-post-info bg-white servdert-one1 ">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/AI/artificial-intelligence (1).png")}
                          alt=""
                        />
                        <h4 className="post-title">AI in Action</h4>
                      </div>
                      <div className="">
                        <p> Discover how AI is being applied across various sectors, including healthcare, finance, education, and entertainment.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow ">
                    <img
                      className="technology-img"
                      src={require("./../../../images//AI/artificial-intelligence.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1 ">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/AI/artificial-intelligence.png")}
                          alt=""
                        />
                        <h4 className="post-title">The Future of AI</h4>
                      </div>
                      <div className="">
                        <p>Explore the potential and challenges of emerging AI technologies like robotics, computer vision, and AI ethics.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* COLUMNS 2 */}
                  <div className="blog-post blog-lg date-style-3 block-shadow">
                    <img
                      className="technology-img"
                      src={require("./../../../images/AI/digitalization.png")}
                      alt=""
                    />
                    <div className="sx-post-info  bg-white servdert-one1">
                      <div className="WhyChooseShadow">
                        <img
                          src={require("./../../../images/AI/digitalization.png")}
                          alt=""
                        />
                        <h4 className="post-title">AI Resources</h4>
                      </div>
                      <div className="">
                        <p>Access valuable resources, including articles, research papers, and educational materials.</p>
                      </div>
                    </div>
                  </div>
                </div>
               

                <div className="col-lg-5 col-md-12 col-sm-12"></div>
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <TypeDesign className="vndjn-popup" />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12"></div>
              </div>
            </div>
          </div>
          
        </div>
      </>
    );
  }
}

export default Blog2;

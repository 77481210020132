import React from 'react';
import Header2 from './../Common/Header3';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import ClienteleBanner from './../Elements/Clientele/ClienteleBanner';
import ClienteleLogo from './../Elements/Clientele/ClienteleLogo';
import ClientsLogo2 from './../Elements/ClientsLogo2';
import { Helmet } from 'react-helmet';




var bnrimg = require('./../../images/banner/shadow-banner-19.jpg');

class Icc extends React.Component {
    render() {
        return (
            <>
            <Helmet>
          <title>
            Our Clientele | Shadow infosystem
          </title>
          <meta name="description" content="Our Clientele at Shadow infosystem includes a wide range of businesses. We are proud to serve clients across various industries with tailored digital solutions" />
          <meta name="keywords" content="Our Clientele | Shadow infosystem" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/clientele" />
        </Helmet>
                <Header2 />
                <div className="page-content">
                    <ClienteleBanner/>
                 


                    <ClienteleLogo />
                    
                    
                    
                </div>
                
                <Footer2 />
            </>
        );
    };
};

export default Icc;
import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import PartnerBanner from "./../Elements/Partner/PartnerBanner";
import PartnerAbout from "./../Elements/Partner/PartnerAbout";
import PartnerService from "./../Elements/Partner/PartnerService";
import { Helmet } from "react-helmet";
import PartnerStep from "./../Elements/Partner/PartnerStep";
import PartnerWhyus from "./../Elements/Partner/PartnerWhyus";
import PartnerWithus from "./../Elements/Partner/PartnerWithus";
import PartnerEnquiry from "./../Elements/Partner/PartnerEnquiry";
import PartnerOnline from "./../Elements/Partner/PartnerOnline";

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Digital Marketing Partnership Program | Shadow infosystem
          </title>
          <meta name="description" content="Join Shadow infosystem's Digital Marketing Partnership Program to boost your business. We offer advanced strategies, expert support, & growth opportunities!" />
          <meta name="keywords" content=" Digital Marketing Partnership Program" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/digital-marketing-partnership-program" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <PartnerBanner />
          <PartnerAbout />
          <PartnerService />
          <PartnerOnline />
          <PartnerWithus />
          <PartnerEnquiry />
          <PartnerStep />
          <PartnerWhyus />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;

import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import SEOPackagesAbout from "./../Elements/SearchEngineOptimizationPackages/SEOPackagesAbout";
import SEOPackagesBanner from "./../Elements/SearchEngineOptimizationPackages/SEOPackagesBanner";
import { Helmet } from "react-helmet";

class Service1 extends React.Component {
  render() {
    return (
      <>
       <Helmet>
          <title>Search Engine Optimization Packages | SEO Packages</title>
          <meta name="description" content="Boost your online visibility with customized and affordable SEO packages from Shadow Infosystem. Our expert services include keyword research, on-page optimization, technical SEO, link building, and more to drive organic traffic and improve rankings. " />
          <meta name="keywords" content="SEO services provider company, Best SEO Services Provider, Best SEO Services Company, Best SEO Services Agency" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/search-engine-optimization-packages" />
     </Helmet>
     <Header2 />
        <div className="page-content">
         <SEOPackagesBanner />
         <SEOPackagesAbout />
       </div>

    <Footer2 />

      </>
    );
  }
}

export default Service1;

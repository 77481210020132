import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";

var bnr = require("./../../images/background/14803753_Vector_2677-1-scaled.jpg");

class Footer2 extends React.Component {
  render() {
    return (
      <>
        <footer className="site-footer footer-large footer-dark footer-wide">
          {/* FOOTER BLOCKES START */}
          <div
            className="footer-top overlay-wraper bg-cover"
            style={{ backgroundImage: "url(" + bnr + ")" }}
          >
            <div className="overlay-main sx-bg-secondry opacity-08" />
            <div className="container">
              <div className="row ploiuytvccc">
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className=" widget_services inline-links">
                    <h5 className="widget-title">
                      <img
                        src={require("./../../images/flag-50.png")}
                        className="county-logo"
                        alt="Shadow infosystem"
                      />{" "}
                      India Office{" "}
                    </h5>
                    <ul className="widget_address">
                      <li>
                        <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                        Electronic City, G-90, Sector-63, Noida-201301 (Delhi /
                        NCR)
                      </li>
                      <li>
                        <a href="tel:+91-8800384880" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="white"
                              fill-rule="evenodd"
                              d="m16.1 13.359l.456-.453c.63-.626 1.611-.755 2.417-.317l1.91 1.039c1.227.667 1.498 2.302.539 3.255l-1.42 1.412c-.362.36-.81.622-1.326.67c-1.192.111-3.645.051-6.539-1.643l3.964-3.963Zm-5.91-5.876l.287-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.065 1.14.453 3.22 2.149 5.776l4.039-4.038Z"
                              clip-rule="evenodd"
                            />
                            <path
                              fill="white"
                              d="M12.063 11.497c-2.946-2.929-1.88-4.008-1.873-4.015l-4.039 4.04c.667 1.004 1.535 2.081 2.664 3.204c1.14 1.134 2.26 1.975 3.322 2.596L16.1 13.36s-1.082 1.076-4.037-1.862Z"
                              opacity=".6"
                            />
                          </svg>{" "}
                          (+91)-8800384880
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className=" widget_services inline-links">
                    <h5 className="widget-title">
                      <img
                        src={require("./../../images/usa-50.png")}
                        className="county-logo"
                        alt="Shadow infosystem"
                      />{" "}
                      USA Office
                    </h5>
                    <ul className="widget_address">
                      <li>
                        <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                        10104 Des Moines ST, <br />
                        Parker CO 80134
                      </li>
                      <li>
                        <a href="tel:+1-720-384-8706" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="white"
                              fill-rule="evenodd"
                              d="m16.1 13.359l.456-.453c.63-.626 1.611-.755 2.417-.317l1.91 1.039c1.227.667 1.498 2.302.539 3.255l-1.42 1.412c-.362.36-.81.622-1.326.67c-1.192.111-3.645.051-6.539-1.643l3.964-3.963Zm-5.91-5.876l.287-.286c.707-.702.774-1.83.157-2.654L9.374 2.86C8.61 1.84 7.135 1.705 6.26 2.575l-1.57 1.56c-.433.432-.723.99-.688 1.61c.065 1.14.453 3.22 2.149 5.776l4.039-4.038Z"
                              clip-rule="evenodd"
                            />
                            <path
                              fill="white"
                              d="M12.063 11.497c-2.946-2.929-1.88-4.008-1.873-4.015l-4.039 4.04c.667 1.004 1.535 2.081 2.664 3.204c1.14 1.134 2.26 1.975 3.322 2.596L16.1 13.36s-1.082 1.076-4.037-1.862Z"
                              opacity=".6"
                            />
                          </svg>{" "}
                          (+1)-720-384-8706
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className=" widget_services inline-links">
                    <h5 className="widget-title">
                      <img
                        src={require("./../../images/canada-1.png")}
                        className="county-logo"
                        alt="Shadow infosystem"
                      />{" "}
                      Canada Office
                    </h5>
                    <ul className="widget_address">
                      <li>
                        <i class="fa fa-map-marker" aria-hidden="true"></i>{" "}
                        11927 85 ST Edmonton <br/>Alberta Canada (T5H 3G2)
                      </li>
                      <li>
                        <a href="mailto:sales@shadowinfosystem.com" target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="currentColor" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 0 1-76.8 0L0 176z"></path></svg>
                          {" "}
                          sales@shadowinfosystem.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-6">
                  <div className="follow-socila">
                    <div className=" widget_about">
                      <h5 className="widget-title">Follow Us</h5>
                      <ul className="social-icons adsfcww  sx-social-links">
                        <li>
                          <a
                            href="https://www.facebook.com/shadowinfosystemlimited"
                            className="fa fa-facebook"
                            target="_blank"
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/shadowinfosystem/"
                            className="fa fa-instagram"
                            target="_blank"
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/shadow-infosystem/"
                            className="fa fa-linkedin"
                            target="_blank"
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/i/flow/login?redirect_after_login=%2Fshadowinfosyste"
                            target="_blank"
                          >
                            <img
                              src={require("./../../images/icon/twitter.png")}
                              className="county-logo1"
                              alt="Shadow infosystem"
                            />
                          </a>
                        </li>

                        <li>
                          <a
                            href="https://www.youtube.com/@Shadowinfosystem"
                            className="fa fa-youtube"
                            target="_blank"
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Head Office */}
                <div className="col-lg-12 col-md-12 col-sm-12 footer-col-3">
                  <div className="widget_services inline-links sdfgkj">
                    <NavLink to={"/csr-at-shadow"}>CSR at SHADOW</NavLink>
                    <NavLink to={"/life-at-shadow"}>Life at SHADOW</NavLink>
                    <NavLink to={"/contact-us"}>NDA</NavLink>
                    <NavLink to={"/icc"}>ICC Members</NavLink>
                    <a
                      href="https://blog.shadowinfosystem.com/"
                      target="_blank"
                    >
                      Blog
                    </a>
                    <NavLink to={"/engagement-models"}>
                      Engagement Models
                    </NavLink>
                    <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink>
                  </div>
                  <div className="widget_services inline-links sdfgkj">
                    <NavLink to={"/terms-and-conditions"}>
                      Terms & Conditions
                    </NavLink>
                    <NavLink to={"/terms-of-services"}>
                      Terms of Service-Digital Marketing
                    </NavLink>
                    <NavLink to={"/career"}>Career</NavLink>
                    <NavLink to={"/case-study"}>Case Study</NavLink>
                    <NavLink to={"/contact-us"}>Contact Us</NavLink>
                    <NavLink className="dspmksn139888" to={"/thanks"}>
                      Contact Us
                    </NavLink>
                  </div>
                  <p className="heading-footer">
                    (An ISO 27001 : 2013 Certified Company)
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="mnxcvb" />
              <div className="container">
                <div className="sduyt456">
                  <span className="copyrights-text">
                    <HelloMessage />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="whatsapp-chat" class="hide">
            <div class="header-chat">
              <div class="head-home">
                <div class="info-avatar">
                  <img
                    src={require("./../../images/shadow-png-logo2-1.png")}
                    className="county-logo"
                    alt="Shadow infosystem"
                  />
                </div>
                <p>
                  <span class="whatsapp-name">Shadow Team</span>
                  <br />
                  <small>We Will Respond You Soon</small>
                </p>
              </div>
              <div class="get-new hide">
                <div id="get-label"></div>
                <div id="get-nama"></div>
              </div>
            </div>
            <div class="home-chat"></div>
            <div class="start-chat">
              <div
                pattern="https://elfsight.com/assets/chats/patterns/whatsapp.png"
                class="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
              >
                <div class="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
                  <div
                    style={{ opacity: 0 }}
                    class="WhatsappDots__Component-pks5bf-0 eJJEeC"
                  >
                    <div class="WhatsappDots__ComponentInner-pks5bf-1 hFENyl">
                      <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotOne-pks5bf-3 ixsrax"></div>
                      <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotTwo-pks5bf-4 dRvxoz"></div>
                      <div class="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotThree-pks5bf-5 kXBtNt"></div>
                    </div>
                  </div>
                  <div
                    style={{ opacity: 1 }}
                    class="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                  >
                    <div class="WhatsappChat__Author-sc-1wqac52-3 bMIBDo">
                      Shadow Team
                    </div>
                    <div class="WhatsappChat__Text-sc-1wqac52-2 iSpIQi">
                      Hi there 👋
                      <br />
                      <br />
                      How can I help you?
                    </div>
                  </div>
                </div>
              </div>

              <div class="blanter-msg">
                <textarea
                  id="chat-input"
                  placeholder="Write a message"
                  maxlength="120"
                  row="1"
                ></textarea>
                <a href="javascript:void;" id="send-it">
                  <svg viewBox="0 0 448 448">
                    <path d="M.213 32L0 181.333 320 224 0 266.667.213 416 448 224z" />
                  </svg>
                </a>
              </div>
            </div>
            <div id="get-number"></div>
            <a class="close-chat" href="javascript:void">
              ×
            </a>
          </div>
          <a class="blantershow-chat" href="javascript:void" title="Show Chat">
            <img
              src={require("./../../images/whatsapp.png")}
              className="county-logo"
              alt="Shadow infosystem"
            />{" "}
          </a>

          {/* FOOTER COPYRIGHT */}
        </footer>

        <Switcher />
      </>
    );
  }
}

export default Footer2;
class HelloMessage extends React.Component {
  render() {
    return (
      <div>
        © Copyright {new Date().getFullYear()} Shadow infosystem (P) Limited All
        Rights Reserved
      </div>
    );
  }
}

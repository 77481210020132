import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
// import AboutBanner from "./../Elements/Awords/AboutBanner";
import AboutAboutUs from "./../Elements/Awords/AboutAboutUs";
import AwardAll from "./../Elements/Awords/AwardAll";
import Footer2 from "./../Common/Footer2";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Testimonials from "./../Elements/Testimonials2";

import Statistics1 from "./../Elements/Statistics1";
import Team1 from "./../Elements/Team1";
import ClientsLogo1 from "./../Elements/ClientsLogo1";
import AboutBanner from "./../Elements/Awords/AboutBanner";
import { Helmet } from "react-helmet";
import AwardsNew from "./../Elements/Awords/AwardsNew";
import NewAwards from "./../Elements/Awords/NewAwards";

var bnrimg = require("./../../images/banner/6.jpg");

class Home4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Celebrating Excellence with Prestigious Awards</title>
          <meta
            name="description"
            content="Get to know our accolades on our Awards Page. Recognitions showcase our commitment to excellence and innovation in IT & SEO solutions."
          />
          <meta name="keywords" content="Top IT Company" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/awards" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <AboutBanner />
          <NewAwards />
          {/* <AwardsNew /> */}
          {/* <AwardAll /> */}
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Home4;

import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-b50 p-t80 back-ground-color`}
        >
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/seo-1.webp")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h1 className="ert678 heading">
                           Search Engine Optimization Packages That Deliver Results
                          </h1>

                          <p>In today’s global marketplace, there is no doubt that any business that wants to expand must need an efficient online platform. Search Engine Optimization Packages at Shadow infosystem are customized to cater to the specific needs of different businesses. Regardless of whether you are a new business or an experienced brand, our packages offer full support in enhancing the visibility of your website as well as its rating on search engines.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-center">
                    {/* <div className="col-md-12">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui"></div>
                      </div>
                    </div> */}
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h2 className="ert678 heading mb-3">
                            Why Choose Our Search Engine Optimization Packages?
                          </h2>
                          <p>Our SEO packages are self-developed to make sure that any business has an equal chance of ranking well on the internet. That is why we work at increasing your website’s traffic using such effective methods as proper choice of keywords, receiving backlinks from high-ranked resources and adjusting technical aspects.</p>
                          <p>Every of our cheap SEO services is created with versatility for every customer in mind. It is for this reason that we value the concept of overhead cost and present to you with solutions that are both optimally effective and affordable.</p>
                          {/*<ul className="sdnvdn">
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Adapting promptly to algorithm updates and
                              industry trends
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Tailoring SEO strategies to meet the
                              distinct requirements of your business
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Conduct in-depth competitor analysis to stay
                              ahead in the evolving SEO landscape.
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Ensuring transparent reporting and
                              facilitating seamless communication
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;Strategically optimizing keywords to boost
                              your website's prominence in search engine results
                            </li>
                          </ul>*/}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 offset-md-1">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top dafeqff fgghhjkhjjg">
                          <img
                            src={require("./../../../images/head-icon/seo-3.webp")}
                            className=""
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                          <img
                            src={require("./../../../images/rb_296.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h2 className="ert678 heading">
                           Affordable SEO Packages for Every Need
                          </h2>
                          <p>Shadow infosystem provides value without compromising quality. Cheap SEO packages help businesses save money while improving online visibility. Our packages involve basic services such as keyword research, on-page optimization, and basic link building.</p>
                          <p>Monthly SEO packages would provide continuous support and updates. This package is helpful in maintaining your website ranking and keeping pace with constantly changing algorithms.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-center">
                    {/* <div className="col-md-12">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui"></div>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h2 className="ert678 heading mb-3">
                            What Do Our SEO Packages Include?
                          </h2>
                          <p>Here’s a glimpse of what you can expect from our SEO services:</p>
                          <ul className="sdnvdn">
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Keyword Research:</b> Suggestions on how to determine the most effective and valuable keywords for your business.

                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>On-Page Optimization:</b> Optimizing website parts such as title tags, headers, and texts for enhanced search engine results.
                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Technical SEO:</b> You need to make sure your website is fast, secure, and optimized for mobile devices.

                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Link Building:</b> Linking out to higher quality sites and getting backlinks that increase your site’s credibility.

                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Content Recommendations:</b> Offering valuable suggestions to develop content according to user demand and trends in the search engine.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-center">
                    
                  <div className="col-md-4">
                      <div className="m-b30 bg-white">
                        <div className="number-block-one  animate-in-to-top dafeqff fgghhjkhjjg">
                          <img
                            src={require("./../../../images/Layer 0 (2).png")}
                            className=""
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="sx-separator-outer separator-left">
                        <div className="ertyui">
                          <h2 className="ert678 heading mb-3">
                            Benefits of Choosing Our SEO Packages
                          </h2>
                          <ul className="sdnvdn">
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Improved Search Visibility:</b> Grow your rankings on niche-based focused strategies.

                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Increased Organic Traffic:</b> Increase the number of people visiting your website through well-developed SEO strategies.

                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Customizable Options:</b> Choose an SEO package that matches your needs and your pocket.

                            </li>
                            <li>
                              <i className="fa fa-check-square-o"></i>{" "}
                              &nbsp;<b>Transparent Reporting:</b> Get regular reports with all necessary information about the course and the return on their investment.
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    
                    <div className="col-md-10 offset-md-1">
                      <div className="sx-separator-outer separator-center">
                        <div className="ertyui">
                          <h2 className="ert678 heading">
                           Monthly SEO Packages for Long-term Success
                          </h2>
                          <p>SEO is not a one-time practice, it needs constant updates and approaches. The SEO services we offer are presented in packages based on months to make sure your website remains competitive on the search engines constantly done with fixed intervals, we keep your company’s operations going through the rapidly changing environment.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    
                    <div className="col-md-12">
                      <div className="sx-separator-outer separator-center">
                        <div className="ertyui">
                          <h2 className="ert678 heading">Who Can Benefit from Our SEO Packages?</h2>
                          <p>At our company, finding the best Search Engine Optimization Packages requires one to consider the size of the business as well as the kind of business. Whether it is an e-commerce store, a local business, or a corporate website, we will have the perfect solution for you.</p>
                          <p>This makes it quite possible for small businesses especially those that have just opened shops to hire our cheap SEO package to help them make their online presence felt without having to spend so much of their investment. As for the growing companies, our more elaborate plans can be used as the basis for faster growth of their marketing activities.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    
                    <div className="col-md-12">
                      <div className="sx-separator-outer separator-center">
                        <div className="ertyui">
                          <h2 className="ert678 heading">
                           Why Work with Shadow infosystem?
                          </h2>
                          <p>At Shadow infosystem, we always strive to provide solutions that meet the goals and needs of our clients. Our specialists go above and beyond to ensure your enterprise achieves the exposure it deserves.</p>
                          <p>Our affordable SEO packages are created with affordability, flexibility, and ROI optimization in mind, making them perfect for any business. Using effective and well-established practices, we make sure that your website is optimized to be what it is expected to be.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="row align-items-end">
                    
                    <div className="col-md-12">
                      <div className="sx-separator-outer separator-center">
                        <div className="ertyui">
                          <h2 className="ert678 heading">
                          Get Started Today
                          </h2>
                          <p>To continue to the next level of your online presence, you can check out our Search Engine Optimization Packages. For start-up businesses who have a limited budget, we offer cheap SEO packages while those who want specific packages that suit their business needs can ask for our monthly SEO packages. Get in touch with Shadow infosystem right now to know your needs and select the <NavLink to={"/search-engine-optimization"} className="bottt2">
                            <b>best SEO packages</b>
                          </NavLink><a></a> that you desire to go for.</p>
                          <p>Get professional and efficient SEO services for your website today and see your online presence blossom in the future.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
